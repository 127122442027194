import { graphql } from 'gatsby'
import * as React from 'react'
import { Col, Container, Row, Visible } from 'react-grid-system'
import { Helmet } from 'react-helmet'
import AllConcepts from '../components/AllConcepts'
import DoYouHaveAnIdea from '../components/DoYouHaveAnIdea'
import Footer from '../components/Footer'
import Header from '../components/Header/Header'
import '../css/index.css'
import { Spacer } from '../theme/base'
import { Title2 } from '../theme/typography'
import { Category } from '../types'
import { parseContentfulFileUrl } from '../util/helpers'

export const query = graphql`
  query {
    topBlob: contentfulAsset(title: { eq: "Blob4" }) {
      fixed(height: 660) {
        srcWebp
      }
    }
    bottomBlob: contentfulAsset(title: { eq: "Blob5" }) {
      fixed(height: 270) {
        srcWebp
      }
    }
  }
`

const IndexPage = ({ data, location }) => {
  const searchParams = new URLSearchParams(location.search)
  const categoryFiltersParam = searchParams.get('category_filters')
  const intitialCategoryFilters = categoryFiltersParam?.split(',') as Category[]

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Work</title>
        <meta
          name="description"
          content="We've worked with many different companies in many different fields. Check out some of our work here."
        />
        <html lang="en" />
      </Helmet>
      <main style={{ position: 'relative' }}>
        <Header />
        <Visible lg xl xxl>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: -10,
            }}
          >
            <Spacer size="header" />
            <img src={parseContentfulFileUrl(data.topBlob.fixed.srcWebp)} />
          </div>
        </Visible>
        <Spacer size="header" />
        <Spacer size="large" />
        <Container>
          <Row>
            <Col>
              <Title2>Our work.</Title2>
            </Col>
          </Row>
          <Spacer h40 />
        </Container>
        <AllConcepts initialCategoryFilters={intitialCategoryFilters} />
        <Spacer size="large" />
        <div style={{ position: 'relative' }}>
          <DoYouHaveAnIdea />
          <Spacer size="large" />
          <Visible lg xl xxl>
            <div
              style={{
                position: 'absolute',
                bottom: -3,
                left: 0,
                zIndex: -10,
              }}
            >
              <img src={parseContentfulFileUrl(data.bottomBlob.fixed.srcWebp)} />
            </div>
          </Visible>
        </div>
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
