import { graphql, useStaticQuery } from 'gatsby'
import React, { FC, useMemo, useState } from 'react'
import Reveal from 'react-awesome-reveal'
import { Container } from 'react-grid-system'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import animations from '../../theme/animations'
import { Spacer } from '../../theme/base'
import { Body2 } from '../../theme/typography'
import { Category, Client } from '../../types'
import { mapCategoryToTags, translateCategory } from '../../util/helpers'
import ConceptCard from '../ConceptCard/ConceptCard'
import Pill from '../__general/Pill'
import { PillListContainer } from '../__general/PillList/styled'

type Props = {
  initialCategoryFilters?: Category[]
}

const query = graphql`
  query {
    allContentfulConcept {
      edges {
        node {
          client {
            slug
          }
        }
      }
    }
    allContentfulClient {
      edges {
        node {
          previewImage {
            fluid(quality: 100) {
              src
              srcWebp
            }
          }
          previewThumbnail: previewImage {
            fluid(quality: 5) {
              srcWebp
            }
          }
          name
          slogan
          slug
          tags
          categories
          contentful_id
        }
      }
    }
  }
`

const AllConcepts: FC<Props> = ({ initialCategoryFilters = [] }) => {
  const { allContentfulClient, allContentfulConcept } = useStaticQuery(query)
  const [categoryFilters, setCategoryFilters] = useState<Category[]>(initialCategoryFilters)

  const conceptSlugs = useMemo(
    () => allContentfulConcept.edges.map(({ node }) => node.client?.slug).filter(Boolean),
    [allContentfulConcept],
  )

  const clients: Client[] = useMemo(() => {
    const allClients = allContentfulClient.edges.map(({ node }) => node)
    const sortedClients = [...allClients].sort((a, b) => {
      if (!conceptSlugs.includes(a.slug) && conceptSlugs.includes(b.slug)) {
        return 1
      }
      if (conceptSlugs.includes(a.slug) && !conceptSlugs.includes(b.slug)) {
        return -1
      }
      return 0
    })
    return sortedClients
  }, [allContentfulClient, conceptSlugs])

  const filteredClients = useMemo(
    () =>
      !categoryFilters.length
        ? clients
        : clients.filter(client =>
            client.categories?.some(category => categoryFilters.includes(category)),
          ),
    [clients, categoryFilters],
  )

  const categoriesWithCount = clients.reduce(
    (acc, client) => {
      client.categories?.forEach(category => {
        if (!acc[category]) acc[category] = 0
        ++acc[category]
      })
      return acc
    },
    { ai: 0, software_development: 0, ui_design: 0, product_design: 0 } as Record<Category, number>,
  )

  const selectedWork = useMemo(
    () =>
      filteredClients.map(client => {
        const activePills = Array.from(new Set(categoryFilters.map(mapCategoryToTags).flat()))

        return (
          <div key={client.contentful_id}>
            <Reveal triggerOnce keyframes={animations.zoomFade}>
              <ConceptCard
                concept={client}
                hasConcept={conceptSlugs.includes(client.slug)}
                activePills={activePills}
              />
            </Reveal>
          </div>
        )
      }),
    [filteredClients],
  )

  const categoryPills = Object.entries(categoriesWithCount)
    .sort((a, b) => b[1] - a[1])
    .map(([category, count]) => {
      const isSelected = categoryFilters.includes(category as Category)

      const onClick = () => {
        if (isSelected) return setCategoryFilters(categoryFilters.filter(c => c !== category))
        setCategoryFilters([...categoryFilters, category as Category])
      }

      const PillComponent = isSelected ? Pill.New : Pill

      return (
        <PillComponent
          onClick={count ? onClick : undefined}
          label={`${translateCategory(category as Category)} (${count})`}
        />
      )
    })

  return (
    <Container>
      <Body2>View a selection of our work.</Body2>
      <Spacer h12 />
      <PillListContainer>{categoryPills}</PillListContainer>
      <Spacer h40 />
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
        <Masonry gutter="80px">{selectedWork}</Masonry>
      </ResponsiveMasonry>
    </Container>
  )
}

export default AllConcepts
